<template>
  <div class="content">
    <NavBar @click-left="onClickLeft" :fixed="true" left-arrow title="搜索"></NavBar>
    <!-- search -->
    <div class="top-search">
      <div class="center-gray">
        <div class="reletive">
          <img class="search-icon" src="../../assets/search.png"/>
          <input :autofocus="focus" type="text" v-model="value" class="input-box" placeholder="请输入商品名称或服务"
                 @input="searchEvent"/>
          <img @click="onClear" v-if="value != ''" class="delet-box" src="../../assets/delet.png"/>
          <div class="btn-sure" @click="submit(value)">确认</div>
        </div>
      </div>
    </div>
    <List
        style="margin-top:95px;"
        v-model="loading"
        :finished="finished"
    >
      <div class="cell" @click="onSelect(item)" v-for="(item, index) in list" :key="index">{{ item }}</div>
    </List>
    <!-- cell -->
    <!--    <div class="card-box" v-if="list.length > 0 && !loading">-->
    <!--      <div class="cell" @click="onSelect(item)" v-for="(item, index) in list" :key="index">{{ item }}</div>-->
    <!--    </div>-->

    <van-empty class="empty" v-if="!loading && list.length === 0" image="search" description="暂无任何数据"/>
  </div>
</template>

<script>
import {NavBar, Toast, List} from 'vant';
import {suggest} from '@/api/api';
import lodash from 'lodash'

export default {
  name: 'searchCompany',
  components: {
    NavBar, List
  },
  data() {
    return {
      loading: false,
      value: '',
      list: [],
      focus: false,
      page: {
        page: 1,
        size: 1000
      },
      searchEvent: null,
      finished: true,
    };
  },
  watch: {
    value(nld) {
      this.getData(nld);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focus = true
    });
    let self = this;
    this.searchEvent = lodash.debounce(async () => {
      self.loading = true;
      let res = await suggest({
        keyword: self.value,
        ...self.page
      })
      if (res.code === 200) {
        self.list = res.data.wvNameList
        let total = res.data.total;
        if(!res.data.wvNameList || self.list.length === 0 ){
          this.finished = true;
        }
      }else{
        this.finished = true;
      }
      setTimeout(() => {
        self.loading = false;
      }, 300)
    }, 500)

    if (this.$route.query.value) {
      this.value = this.$route.query.value
    }
  },
  methods: {
    onClear() {
      this.value = '';
    },
    onSelect(item) {
      if (!item) return Toast('请输入产品名称或服务');
      this.value = item;
      this.submit()
    },
    getData(e = '') {

    },
    onClickLeft() {
      this.$router.go(-1)
    },
    submit() {
      this.$router.push(`/fbw?search=${this.value}`)
    },
    onLoad() {
      this.searchEvent()
    },
  }
};
</script>

<style scoped>
.content {
  width: 100%;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

.top-search {
  height: 44px;
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  top: 46px;
  background: #ffffff;
  overflow: hidden;
}

.center-gray {
  position: absolute;
  left: 10px;
  right: 10px;
  height: 32px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px;
  background: #f5f5f5;
  overflow: hidden;
}

.reletive {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-sure {
  width: 66px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #006edc;
  border-radius: 14px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 2px;
  opacity: 1;
  font-size: 13px;
}

.btn-sure:active {
  opacity: 0.8;
}

.search-icon {
  position: absolute;
  left: 7px;
  top: 4px;
  width: 24px;
  height: 24px;
}

.delet-box {
  position: absolute;
  right: 70px;
  top: 0;
  width: 20px;
  height: 20px;
  padding: 6px;
}

.input-box {
  position: absolute;
  left: 38px;
  top: 0;
  width: 100%;
  height: 32px;
  border: none;
  background: #f5f5f5;
  font-size: 14px;
  color: #000000;
  caret-color: #006edc;
}

.input-box:focus {
  font-size: 14px;
  color: #000000;
}

.card-box {
  width: calc(100% - 20px);
  background: #ffffff;
  margin-top: 106px;
  min-height: calc(100vh - 120px);
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  overflow: hidden;
  height: auto;
}

.cell {
  position: relative;
  overflow: hidden;
  line-height: 30px;
  padding:5px 10px;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}

.cell::after {
  content: '';
  height: 1px;
  width: calc(100% - 9px);
  left: 50%;
  transform: translateX(-50%);
  background: #d2d2d2;
  bottom: 0;
  position: absolute;
}

.empty {
  height: calc(100vh);
  width: 100%;
}
</style>
